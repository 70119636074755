import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  LOCALE_ID,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { getLocaleDirection, DOCUMENT } from '@angular/common';
import { CustomIconService } from '../../services/custom-icon.service';
import { MatSidenav } from '@angular/material/sidenav';
import { distinctUntilChanged, filter, Observable, Subject, take, takeUntil } from 'rxjs';
import { CreateTicketComponent } from '@shared/components/create-ticket/create-ticket.component';
import { NavigationStart, Router } from '@angular/router';
import { CreateTicketService } from '@shared/services/create-ticket.service';
// import { Intercom, IntercomBootInput } from '@supy-io/ngx-intercom';
import { environment } from '@env/environment';
import { Profile } from '@auth/interfaces/profile.interface';
import { Store } from '@ngrx/store';
import { State } from '@auth/store';
import { selectAuthUserProfile } from '@auth/store/selectors/profile.selectors';
import { FileLoaderService } from '@core/services/file-loader.service';

declare var fwcrm: any;
declare var fcWidget: any;

@Component({
  selector: 'app-app-root',
  templateUrl: './app-root.component.html',
  styleUrls: ['./app-root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppRootComponent implements OnInit, OnDestroy {
  @ViewChild('contentSideNav') public contentSideNav!: MatSidenav;
  @ViewChild('sideBarContainer', { read: ViewContainerRef }) sideBarContainerRef!: ViewContainerRef;
  public navMenuExpanded: boolean = true;
  public readonly userPersonalData$: Observable<Profile | null>;

  private readonly dir = getLocaleDirection(this.locale);
  private readonly destroyed$ = new Subject<void>();
  private chat: any;

  constructor(
    private customIconService: CustomIconService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(DOCUMENT) private document: Document,
    private r: Renderer2,
    private createTicketService: CreateTicketService,
    private router: Router,
    // private intercom: Intercom,
    private store: Store<State>,
    private cdr: ChangeDetectorRef,
    private fileLoaderService: FileLoaderService
  ) {
    this.customIconService.registerIcons();
    // TODO: Use this approach to add light/dark theme
    if (this.dir === 'rtl') {
      this.r.addClass(this.document.body, 'rtl');
    }

    this.createTicketService.sideBarComp$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.toggleSideNav();
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      if (this.contentSideNav) {
        this.contentSideNav.close();
      }
    });

    this.userPersonalData$ = this.store.select(selectAuthUserProfile).pipe(distinctUntilChanged());
  }

  ngOnInit(): void {
    console.log('environment', environment);
    // this.userPersonalData$.pipe(takeUntil(this.destroyed$)).subscribe((customerData: Profile | null) => {
    //   // console.log('customerData', customerData);
    //   if (customerData) {
    //     // Remove previous script
    //     this.fileLoaderService.removeScript('chatLoggedOut').then((res) => {
    //       // Load new script
    //       this.fileLoaderService.load('chatLoggedIn').then((res) => {
    //         // console.log('chatLoggedIn Loaded', res);
    //         // console.log('fwcrm', fwcrm);
    //         if (fwcrm) {
    //           fwcrm.on('user:created', function () {
    //             fcWidget.setExternalId(customerData.objectId);
    //             fcWidget.user.setFirstName(customerData.name);
    //             fcWidget.user.setEmail(customerData.email);
    //             fcWidget.user.setPhone(customerData.phone);
    //           });
    //         }
    //       });
    //     });
    //   } else {
    //     if (this.fileLoaderService.isScriptLoaded('chatLoggedIn')) {
    //       // Remove previous script
    //       this.fileLoaderService.removeScript('chatLoggedIn').then((res) => {
    //         // Load new script
    //         this.fileLoaderService.load('chatLoggedOut').then((res) => {
    //           // console.log('chatLoggedOut Loaded', res);
    //         });
    //       });
    //     } else {
    //       // Load new script
    //       this.fileLoaderService.load('chatLoggedOut').then((res) => {
    //         // console.log('chatLoggedOut Loaded', res);
    //       });
    //     }
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onToggleNavMenu(isExpanded: boolean): void {
    this.navMenuExpanded = isExpanded;
  }

  private toggleSideNav(): void {
    const viewContainerRef = this.sideBarContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<CreateTicketComponent>(CreateTicketComponent);
    componentRef.instance.closeSideNav.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.contentSideNav.close();
      //Detect change to fix submit ticket not closing the side nav from first click
      this.cdr.detectChanges();
    });
    this.contentSideNav.open();
  }
}
